import { createSlice } from "@reduxjs/toolkit";


const Global= createSlice({
    name: 'Global',
    initialState: {
        menuAction: false,
        user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        ringSize: null,
        cartItems: null
    },
    reducers:{
        MenuFilter(state,action){
            state.menuAction = action.payload
        },
        UserData(state,action){
            state.user = action.payload
            localStorage.setItem("user", JSON.stringify(action.payload))
        },
        RingSize(state,action){
            state.ringSize = action.payload
        },
        CartData(state,action){
            state.cartItems= action.payload
        },
        
    }
})


export default Global.reducer;
export const {MenuFilter,UserData,RingSize,CartData}=Global.actions;