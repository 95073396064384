import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Image,
  Nav,
  FormControl,
  Button,
  Navbar,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import "../../assets/css/header.css";
import logoImage from "../../assets/images/logo.svg";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory, NavLink, Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  makeStyles,
  Typography,
  TextField,
  CircularProgress
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import menuImages from "../../assets/images/icon/menu.png";
import { SlideDown } from "react-slidedown";
import "../../../node_modules/react-slidedown/lib/slidedown.css";
import { base_url, getData, postData } from "../../Helpers/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Login from "../../Components/Login";
import ReCAPTCHA from "react-google-recaptcha";
import { IoMdCall } from "react-icons/io";
import { BsTruck} from "react-icons/bs";
import { BiSearch, BiHeart } from "react-icons/bi";
import { AiOutlineClose, AiOutlineShoppingCart } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import MiniCart from "../Pages/MiniCart";
import Productlist from "../Pages/productlist";
import { useDispatch } from "react-redux";
import { MenuFilter } from "../../store/slices/Global";
import OnlyRedux from "../../store/slices/OnlyRedux";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100%",
  },
});

const Navigation = ({ menudata }) => {

  // setTimeout(() => {
  //   var user1 = {
  //     token: "ff7893e7f9748969d2897aded194e28229a4e844",
  //     user_id: 882,
  //     email: "test15@gmail.com",
  //     first_name: "test15@gmail.com",
  //     last_name: "test15@gmail.com",
  //     isLogin: true
  //   }

  //   const loginResponseJSON = JSON.stringify(user1);
  //   localStorage.setItem("user",loginResponseJSON)
  // }, 1000);
  

  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  const dispatch= useDispatch();

  var topNotify=localStorage.getItem('topNotification');
  const [stickyHeader, setstickyHeader] = useState(false);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY;
      if (isTop > 150) {
        setstickyHeader("topDown");
      }
      else{
        setstickyHeader("topUp");
      }
    });
  }, []);

  localStorage["myKey"] = "somestring";
  const history = useHistory();
  const [idno, setIdno] = useState(false);
  const [displaybox, setDisplaybox] = useState(false);
  const [left, setLeft] = useState(false);
  const classes = useStyles();
  // localStorage.getItem("currency_type") ? localStorage.getItem("currency_type") : localStorage.setItem("currency_type", 'USD');
  // localStorage.getItem("currency_img") ? localStorage.getItem("currency_img") : localStorage.setItem("currency_img", USA);
  // localStorage.getItem("currency_text") ? localStorage.getItem("currency_text") : localStorage.setItem("currency_text", "$");
  const [dropdown, setDropdown] = useState();
  const [search_show, setSearch_show] = useState(false);
  const [alt, setAlt] = useState();
  const [appointment, setAppointment] = useState(false);
  const [miniCart, setminiCart] = useState(false);

  const MiniCartCallBack=(v)=>{
    setminiCart(v);
  }



  const [currencydata, setCurrencydata] = useState([]);

  const [searchtext, setSearchtext] = useState("");

  //appointment
  const [apmtfirstname, setApmtfirstname] = useState("");
  const [apmtlastname, setApmtlastname] = useState("");
  const [apmtemail, setApmtemail] = useState("");
  const [apmtphone, setApmtphone] = useState("");
  const [apmtdate, setApmtdate] = useState("");
  const [apmttime, setApmttime] = useState("");
  const [apmtcomment, setApmtcomment] = useState("");
  //alert toast
  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);

  const [cartlength, setCartlength] = useState(
    localStorage.getItem("cartlength") ? localStorage.getItem("cartlength") : 0
  );
  const [wishlistlength, setWishlistlength] = useState(
    localStorage.getItem("wishlistlength")
      ? localStorage.getItem("wishlistlength")
      : 0
  );

  const [email, setEmail] = useState("");
  const [totalProductCount, setTotalProductCount] = useState("");
  const [productdata, setProductdata] = useState([]);

  const footerdata = JSON.parse(localStorage.getItem("footerdetails"));
  const [hovered, setHovered] = useState(false);
  const [indexing, setIndexing] = useState(null);
  const [capchaloading, setCapchaloading] = useState(true);
  const [offers, setoffers] = useState(topNotify=='true' ? true : false);

  const handleappointment = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    var data = {
      first_name: apmtfirstname,
      last_name: apmtlastname,
      email: apmtemail,
      phone_no: apmtphone,
      meeting_datetime: moment(apmtdate + " " + apmttime, "DD/MM/YYYY HH:mm:ss")
        ._i,
      comment: apmtcomment,
    };
    postData(`/user/appointment`, data)
      .then((response) => {
        if (response.status == 1) {
          toast.success(response.message, { autoClose: 3000 });
          setAppointment(false);
          setApmtlastname("");
          setApmtfirstname("");
          setApmtemail("");
          setApmtphone("");
          setApmtdate("");
          setApmttime("");
          setApmtcomment("");
          setButtonLoading(false);
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setoffers(topNotify===null ? true : false)
    currencyapi();
    setInterval(() => {
      setCartlength(
        localStorage.getItem("cartlength")
          ? localStorage.getItem("cartlength")
          : 0
      );
      setWishlistlength(
        localStorage.getItem("wishlistlength")
          ? localStorage.getItem("wishlistlength")
          : 0
      );
    }, 1000);
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 96);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
   
  }, []);
  const currencyHandle = (type, img, text) => {
    setDropdown({ img: img, text: type });
    localStorage.setItem("currency_type", type);
    localStorage.setItem("currency_img", img);
    localStorage.setItem("currency_text", text);
    window.location.reload();
  };
  const displaydata = (i) => {
    setHovered(true);
    setIndexing(i);
    if (i == 0) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 1) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 2) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 3) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 4) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 5) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 6) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 7) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 8) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 9) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 10) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 11) {
      setDisplaybox(true);
      setIdno(i);
    } else if (i == 12) {
      setDisplaybox(true);
      setIdno(i);
    }
  };
  const handleclickcategory = (event,x, value) => {
    sessionStorage.removeItem("list_filter");
    setDisplaybox(false);
    setHovered(false);
    if (event.ctrlKey) {
      if(screenWidth >= 1025){
        var dynamicUrl= ("/category/" + x + "/" + value).replaceAll(" ", "-").toLowerCase();
      } else{
        var dynamicUrl= ("/category/" + event + "/" + x).replaceAll(" ", "-").toLowerCase();
      }
      window.open(dynamicUrl, "_blank");
    }else{
      if(screenWidth >= 1025){
        history.push(
          ("/category/" + x + "/" + value).replaceAll(" ", "-").toLowerCase()
        );
      }else{
        history.push(
          ("/category/" + event + "/" + x).replaceAll(" ", "-").toLowerCase()
        );
      }
     
    }
  };

  const handleclick = (event,x, y, z, subvalue, value) => {
    if (subvalue.includes("Style")) {
      var filter = { Style: [subvalue.replace("Style=", "")] };
    } else if (subvalue.includes("Shape")) {
       filter = { Shape: [subvalue.replace("Shape=", "")] };
    } else if (subvalue.includes("Gemstone")) {
       filter = { Gemstone: [subvalue.replace("Gemstone=", "")] };
    } else if (subvalue.includes("MetalColor")) {
       filter = { MetalColor: [subvalue.replace("MetalColor=", "")] };
    } else if (subvalue.includes("Gender")) {
       filter = { Gender: [subvalue.replace("Gender=", "")] };
    } else if (subvalue.includes("Price")) {
       filter = {
        PriceFrom: subvalue.replace(/&.*/g, "").replace("PriceFrom=", ""),
        PriceTo: subvalue.replace(/.*&/g, "").replace("PriceTo=", ""),
      };
    }
    sessionStorage.setItem("list_filter", JSON.stringify(filter));
    setDisplaybox(false);
    setLeft(false);
    if (event.ctrlKey) {
      var dynamicUrl=null;
      if(value=="Gender=Male" && z=="White Gold"){
        dynamicUrl=("/productlist/" + x + "/" + y + "/" + z + "/" + value + "/" + subvalue).toLowerCase();
      }else{
        dynamicUrl=("/productlist/" + x + "/" + y + "/" + z + "/" + value + "/" + subvalue).replaceAll(" ", "-").toLowerCase();
      }
      
      window.open(dynamicUrl, "_blank");
    }else{
      var dynamicUrl=null;
      if(value=="Gender=Male" && z=="White Gold"){
        dynamicUrl=("/productlist/" + x + "/" + y + "/" + z + "/" + value + "/" + subvalue).toLowerCase();
      }else{
        dynamicUrl=("/productlist/" + x + "/" + y + "/" + z + "/" + value + "/" + subvalue).replaceAll(" ", "-").toLowerCase();
      }
      history.push(dynamicUrl);
    }
    
    dispatch(MenuFilter(false));
  };
  
  const toggleDrawer = (open) => {
    setLeft(open);
  };
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 128);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handlesubscribe = () => {
    postData("/user/newsLetter", { email })
      .then((res) => {
        if (res.status == 1) {
          toast.success(res.message, { autoClose: 3000 });
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetAppointment = (event) => {
    setApmtfirstname("");
    setApmtlastname("");
    setApmtemail("");
    setApmtdate("");
    setApmtphone("");
    setApmttime("");
    setApmtcomment("");
  };
  const handleCancel = () => {
    setAppointment(false);
    resetAppointment();
  };
  const searchItems = (val) => {
    setSearchtext(val);
  };
  const handlesearch = (text) => {
    var user_id = JSON.parse(localStorage.getItem("user"))?.user_id
    ? JSON.parse(localStorage.getItem("user"))?.user_id
    : "";
  var session_id = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : "";

    getData(`/api/productList?search_text=${text}&${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
      .then((response) => {
        if (response.status == 1) {
          setTotalProductCount(response.data.count);
          setProductdata(response.data.results);
          setLoading(false);
        } else {
          setProductdata([]);
          setTotalProductCount(0);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openDetail = (item) => {
    window.scrollTo(0, 0);
    setSearchtext("");
    history.push(
      "/productdetail/" +
        item.product_slug +
        "/" +
        item.variant +
        (item.stone1 ? "/" + item.stone1 : "") +
        (item.stone2 ? "/" + item.stone2 : "") +
        (item.stone3 ? "/" + item.stone3 : "")
    );
  };
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const currencyapi = () => {
    getData(`/api/currency`)
      .then((response) => {
        if (response.status == 1) {
          setCurrencydata(response.data);
          localStorage.getItem("currency_type")
            ? localStorage.getItem("currency_type")
            : localStorage.setItem(
                "currency_type",
                response.data[0]?.abbreviations
              );
          localStorage.getItem("currency_img")
            ? localStorage.getItem("currency_img")
            : localStorage.setItem(
                "currency_img",
                base_url + response.data[0]?.country_flag
              );
          localStorage.getItem("currency_text")
            ? localStorage.getItem("currency_text")
            : localStorage.setItem(
                "currency_text",
                response.data[0]?.currency_icon
              );
          setDropdown({
            img: localStorage.getItem("currency_img"),
            text: localStorage.getItem("currency_type"),
          });
          setLoading(false);
        } else {
          setProductdata([]);
          setTotalProductCount(0);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const capchastatus = (res) => {
    console.log(res);
    if (res) {
      setCapchaloading(false);
    }
  };
  const expireCaptcha = () => {
    setCapchaloading(true);
  };

  const notificationHandle=()=>{
    localStorage.setItem('topNotification','false');
    setoffers(false);
  }
  const setter=()=>{
    dispatch(OnlyRedux(true))
  } 
 

  return (
    <div id="navbar">

      <ToastContainer limit={1} />
      {offers && (
        <div className="gs_top_header_text_main">
          <Container className="">
            <Row>
              <Col>
                <div className="gs_top_header_text_div">
                  <p className="gs_roboto">
                    Complimenary Engraving Available for a Limited Time
                  </p>
                  <BsXLg
                    className="top_header_close_icn "
                    onClick={() => notificationHandle() }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <div className="gs_top_head">
        <Container className="gs_customer_container">
          <Row className="m-0">
            <div className="gs_top_header_section w-100">
              <Row>
                <Col xs={7} md={5} lg={6} xl={6} className="p-0">
                  <div className="gs_top_head_left">
                    <ul>
                      <li>
                        <Dropdown className="d-inline mx-2 gs_dropdown_lang">
                          <Dropdown.Toggle id="dropdown-autoclose-true">
                            <Image
                              src={dropdown?.img}
                              style={{ width: "25px" }}
                            ></Image>
                            <span>{dropdown?.text}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {currencydata.map((val,i) => (
                              <Dropdown.Item
                              key={i}
                                onClick={() => {
                                  currencyHandle(
                                    val.abbreviations,
                                    base_url + val.country_flag,
                                    val.currency_icon
                                  );
                                }}
                                href="#"
                              >
                                <Image
                                  src={base_url + val.country_flag}
                                  alt={val.abbreviations}
                                ></Image>
                                <span> {val.abbreviations}</span>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li>
                        <Button variant="link">
                          <span className="d-none d-md-block">
                            <IoMdCall />
                            <span> +1 (877) 706-5333</span>
                          </span>
                        </Button>
                      </li>

                      <li>
                        <Button variant="link">
                          <span className="d-none d-md-block">
                            <BsTruck />
                            <span className="gsk_Shipping_text">
                              Free 2 Days Shipping
                            </span>
                          </span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={5} md={7} lg={6} xl={6} className="p-0">
                  <ul className="gs_top_head_right">
                    <li
                      className="d-none d-md-block"
                      onClickAway={() => setSearch_show(false)}
                    >
                      <Link to="#">
                        {" "}
                        <span onClick={() => setSearch_show(!search_show)}>
                          <BiSearch style={{ cursor: "pointer" }} />
                        </span>{" "}
                      </Link>

                      <Modal
                        className={
                          isScrollValueMoreThanHeaderHeight
                            ? "rcs_search_modal12"
                            : "rcs_search_modal"
                        }
                        show={search_show}
                        onHide={() => setSearch_show(false)}
                      >
                        <div className="rcs_search_bar">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              sessionStorage.removeItem("list_filter");
                              if (searchtext) {
                                handlesearch(searchtext);
                                history.push(`/searchlist/${searchtext}`);
                                setSearch_show(false);
                              }
                              setSearchtext("");
                            }}
                          >
                            <SearchIcon />

                            <FormControl
                              value={searchtext}
                              placeholder="Search..."
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) => searchItems(e.target.value)}
                            />

                            <AiOutlineClose
                              onClick={() => setSearch_show(!search_show)}
                            />
                          </Form>
                        </div>
                      </Modal>
                    </li>

                    {/* <Login /> */}
 
                      <Login/>
                  
                    <li
                      className="d-none d-md-block"
                      onClick={() => {
                        history.push("/wishlist");
                      }}
                    >
                      <NavLink to="#">
                        <span>
                          <BiHeart />
                          <span>Wish</span> ({wishlistlength})
                        </span>
                      </NavLink>
                    </li>
                    <li
                    id='cartTrigger'
                      className="d-none d-md-block position-relative"
                      onClick={() => {
                       // history.push('/cart')
                       cartlength!=="0" && setminiCart(true)
                      }}
                    >
                      <NavLink to="#" className='miniCartTrigger'>
                        <span>
                          <AiOutlineShoppingCart /> <span>Bag</span> (
                          {cartlength})
                        </span>
                      </NavLink>

                   
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>

      <div
        className={
          isScrollValueMoreThanHeaderHeight
            ? "gs_logo_head gs_sticky-top"
            : "gs_logo_head"
        }
      >
        <Container className="gs_customer_container d-block">
          <Row className="m-0">
            <div className="gs_logo_head_section w-100">
              <Row className="m-0">
                <Col xs={12} md={4} lg={4} xl={4} className="p-0">
                  <Nav className="me-auto gs_mobile_header_menu">
                    <Button onClick={() => toggleDrawer(true)}>
                      <Image src={menuImages} alt="menu icon"></Image>
                    </Button>
                  </Nav>
                </Col>
                <Col xs={12} md={4} lg={4} xl={4}>
                  <div className="gs_logo_section">
                    <NavLink exact to="/" onClick={() => window.scrollTo(0, 0)}>
                      <Image src={logoImage} alt="Gem Stone King" />
                    </NavLink>
                    <ul className="d-block d-md-none">
                      {/* <li
                        onClick={() => {
                          history.push("/wishlist");
                        }}
                      >
                        <NavLink to="#">
                          <span onClick={() => window.scrollTo(0, 0)}>
                            <Image src={wishlistImage}></Image>
                            <sup> {wishlistlength} </sup> 
                          </span>
                        </NavLink>
                      </li> */}
                      <li>
                        <Login />
                      </li>
                      <li
                        onClick={() => {
                          history.push("/cart");
                        }}
                      >
                        <NavLink to="#">
                          <span onClick={() => window.scrollTo(0, 0)}>
                            {/* <Image src={cartImage}></Image>  */}
                            <FiShoppingCart />
                            <sup> {cartlength} </sup>
                          </span>
                        </NavLink>
                      </li>
                    </ul>

                   
                  </div>
                  <div className="gsk_mobileSearch_main">
                      <div className="rcs_search_bar">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            sessionStorage.removeItem("list_filter");
                            if (searchtext) {
                              handlesearch(searchtext);
                              history.push(`/searchlist/${searchtext}`);
                              setSearch_show(false);
                            }
                            setSearchtext("");
                          }}
                        >
                          <SearchIcon />

                          <FormControl
                            value={searchtext}
                            placeholder="Search here"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => searchItems(e.target.value)}
                          />

                          {/* <AiOutlineClose
                            onClick={() => setSearch_show(!search_show)}
                          /> */}
                        </Form>
                      </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4} xl={4} className="p-0"></Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
      {/* class for sticky d-lg-block  gs_sticky-top1 */}
      <Navbar
        className={`${stickyHeader} ${isScrollValueMoreThanHeaderHeight
            ? "gs_navbar w-100 d-none "
            : "gs_navbar w-100 d-none d-lg-block"}`}
        variant="light"
      >
        <Container fluid className="gs_customer_container_fluid">
          <div className="gs_logo_section1">
            <NavLink exact to="/" onClick={() => window.scrollTo(0, 0)}>
              <Image src={logoImage} alt="Gem Stone King" />
            </NavLink>
          </div>
          <Nav
            className={
              isScrollValueMoreThanHeaderHeight
                ? "me-auto gs_desktop_header_menu gs_dropdown_top"
                : "me-auto gs_desktop_header_menu"
            }
          >
            <ul className="gs_navbar_ul">
              {menudata?.map((item, index) => (
                <li
                key={index}
                  onMouseEnter={(e) => displaydata(index)}
                  onMouseLeave={(e) => {
                    setDisplaybox(false);
                    setHovered(false);
                  }}
                  className={
                    indexing == index
                      ? hovered
                        ? item.submenu?.length
                          ? "menu gs_menu_active"
                          : "menu"
                        : "menu"
                      : "menu"
                  }
                >
                  {/* <NavLink to="#" className="nav-link" >{item.menu_name}</NavLink> */}
                  <div
                    className="nav-link"
                    onClick={(event) =>
                      handleclickcategory(event,item.menu_name, item.value)
                    }
                  >
               
                    <span onClick={() => window.scrollTo(0, 0)}>
                      {item.menu_name}
                    </span>
                  </div>
                  {displaybox && idno == index && item.submenu?.length > 0 ? (
                    <div className="gs_dropdown_menu">
                      <div className="p-0">
                        <Row className="m-0 w-100">
                          <div className="gs_dropdown_cotnent">
                            <Container className="">
                              {item.submenu?.map((submenu,i) => (
                                submenu.submenu_name!=="PRICE" &&
                                <Col className="customCol" mx-1="true" key={i}>
                                  <div className="gs_inner_dropdown_menu">
                                    <h2>{submenu.submenu_name}</h2>
                                    <SlideDown
                                      className={"my-dropdown-slidedown"}
                                    >
                                      <ul>
                                        {submenu.menuitems?.map((menuitem,ind) => (
                                         menuitem.status_id==1 &&
                                          <li
                                            onClick={() => {
                                              window.scrollTo(0, 0);
                                              setHovered(false);
                                            }}
                                            key={ind}
                                          >
                                         
                                            <span
                                              onClick={(event) =>
                                                handleclick(
                                                  event,
                                                  item.menu_name,
                                                  submenu.submenu_name,
                                                  menuitem.item_name,
                                                  menuitem.value,
                                                  item.value
                                                )
                                              }
                                            >
                                              {menuitem.item_name}
                                            </span>
                                          </li>
                                        ))}
                                        

                                      </ul>
                                      {/* <ChevronRightIcon /> */}
                                    </SlideDown>
                                  </div>
                                  
                                </Col>
                              ))}
                              <Col mx-1="true">
                                <img src={'https://api.gemstoneking.com'+item?.image} className="img-fluid" alt="" />
                              </Col>
                            </Container>
                          </div>
                        </Row>
                      </div>
                    </div>
                  ) : (
                    <SlideDown className={"my-dropdown-slidedown"}> </SlideDown>
                  )}
                </li>
              ))}
            </ul>
          </Nav>
          <Drawer anchor="left" open={left} onClose={() => toggleDrawer(false)}>
            <div role="presentation" onKeyDown={() => toggleDrawer(false)}>
              <div className="gs_mobile_menu gs_accordion_sec">
                <div className={classes.root}>
                  {menudata?.map((item, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h3
                          className="gs_accord_title"
                          onClick={() => {
                            handleclickcategory(item.menu_name, item.value);
                            toggleDrawer(false);
                          }}
                        >
                          {item.menu_name}
                        </h3>
                      </AccordionSummary>
                      {item.submenu?.map((submenu,i) => (
                        <AccordionDetails key={i}>
                          <h2>{submenu.submenu_name}</h2>
                          <Typography>
                            <ul>
                              {submenu.menuitems?.map((menuitem) => (
                                <li onClick={() => window.scrollTo(0, 0)}>
                                  <a
                                    href="#"
                                    onClick={(event) =>
                                      handleclick(
                                        event,
                                        item.menu_name,
                                        submenu.submenu_name,
                                        menuitem.item_name,
                                        menuitem.value,
                                        item.value
                                      )
                                    }
                                  >
                                    <ChevronRightIcon />
                                    {menuitem.item_name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </Typography>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  ))}
                  <div className="gs_extra_link">
                    <NavLink
                      to="/pages/our-story"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <span
                        onClick={() => {
                          setLeft(false);
                        }}
                      >
                        About Us
                      </span>
                    </NavLink>
                    <NavLink to="/pages/faqs" onClick={() => window.scrollTo(0, 0)}>
                      <span
                        onClick={() => {
                          setLeft(false);
                        }}
                      >
                        FAQ'S
                      </span>
                    </NavLink>
                  </div>
                  <div className="gs_extra_link_bottom">
                    <h2>Contact Us</h2>
                    <ul>
                      <li>
                        <NavLink
                          to="#"
                          onClick={(e) => {
                            window.location.href = `tel:${footerdata?.footerinfo[0].mobile_no}`;
                          }}
                        >
                          <CallIcon /> <span>Call</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="#"
                          onClick={(e) => {
                            window.location.href = `mailto:${footerdata?.footerinfo[0].email}`;
                          }}
                        >
                          <EmailIcon /> <span>Email</span>
                        </NavLink>
                      </li>
                      <li onClick={() => window.scrollTo(0, 0)}>
                        <NavLink
                          onClick={() => setLeft(false)}
                          to="/pages/contact-us"
                        >
                          <PermContactCalendarIcon /> <span>Contact</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </Container>
      </Navbar>
      <Modal
        className="gs_login_modal gs_appointment_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={appointment}
        onHide={() => {
          setAppointment(false);
          setCapchaloading(true);
        }}
      >
        <Modal.Header className="gs_modal_header" closeButton></Modal.Header>
        <Modal.Body>
          <div className="gs_appointment_input">
            <Modal.Title>SCHEDULE AN APPOINTMENT</Modal.Title>
            <p>
              Please use the form below or call us at
              <NavLink
                to="#"
                onClick={(e) => {
                  window.location.href = `tel:${footerdata?.footerinfo[0].mobile_no}`;
                }}
              >
                {footerdata?.footerinfo[0].mobile_no}
              </NavLink>
              to schedule your personalized visit with a jewelry specialist.
            </p>
            <Form onSubmit={handleappointment}>
              <Row>
                <Col xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    type="text"
                    variant="outlined"
                    value={apmtfirstname}
                    onChange={(e) => setApmtfirstname(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    type="text"
                    variant="outlined"
                    value={apmtlastname}
                    onChange={(e) => setApmtlastname(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    type="Email"
                    variant="outlined"
                    value={apmtemail}
                    onChange={(e) => setApmtemail(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    type="number"
                    variant="outlined"
                    value={apmtphone}
                    onChange={(e) => setApmtphone(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="Date"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={apmtdate}
                    onChange={(e) => setApmtdate(e.target.value)}
                    required
                    inputProps={{
                      min: date,
                    }}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField
                    id="time"
                    label="Time"
                    type="time"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 30 min
                    }}
                    value={apmttime}
                    onChange={(e) => setApmttime(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="outlined-basic"
                    label="Comment"
                    type="text"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={apmtcomment}
                    onChange={(e) => setApmtcomment(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ReCAPTCHA
                    sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                    onChange={capchastatus}
                    onExpired={expireCaptcha}
                  />
                </Col>
              </Row>
              <Modal.Footer>
                <Button
                  className="gs_fill_button"
                  type="submit"
                  disabled={capchaloading}
                >
                  Submit
                  {buttonLoading ? (
                    <CircularProgress className="gs_loader" />
                  ) : (
                    ""
                  )}
                </Button>
                <Button
                  className="gs_outline_button"
                  onClick={() => resetAppointment()}
                >
                  Reset
                </Button>
                <Button
                  className="gs_fill_light_button"
                  onClick={() => {
                    handleCancel();
                    setCapchaloading(true);
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/* mini cart */}
      <Modal
        className="gs_login_modal fade rightSideModal miniCartModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={miniCart}
        onHide={() =>{setminiCart(false); sessionStorage.setItem('isCartAdded','false')}}
      >
        <Modal.Header className="gs_modal_header" closeButton></Modal.Header>
        <Modal.Body>
          <div className="gs_appointment_input">
            <MiniCart callback={MiniCartCallBack}/>
           
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Navigation;
