import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Form, FormGroup, Container, Row, Col, Image } from 'react-bootstrap';
import { FormControlLabel, Checkbox, Button, TextField, Divider, Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import '../../assets/css/wishlist.css'
import { makeStyles } from '@material-ui/core/styles';
import payment_icon from "../../assets/images/footer/payment_icon.svg";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Helmet } from 'react-helmet';
import Paypal from '../../Components/Paypal';
import { getData, isLogin, postAuthData, postData } from '../../Helpers/request';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobileOnly, isTablet } from 'react-device-detect';
import usStates from "./statesUs.json"
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Stripe from '../../Components/Stripe';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 110,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Checkout = () => {

    // const stripePromise = loadStripe("pk_test_51HsGQaD5ghLyBOwkpVHUISkXg0oocfRr6bSE7zAfpFkx15bP5J0u9iAojhJI0xnJL9GYeP8L7SPYlxLPM64RPjG700V0zgqr2T");
    const stripePromise = loadStripe("pk_live_51HsGQaD5ghLyBOwk6sPHgBzkXFSfO0yWdf86xR1AKYLwNcyIbvBKIedpWnxZ0N5SF64AVpDHotP9vDqwgqUc6Ref00VgvrnD1k");
    const history = useHistory();
    const [shippingaddress, setShippingaddress] = useState(null);
    const [payment_mode, setPayment_mode] = useState(2);
    const [billingaddress, setBillingaddress] = useState(null);
    const [addressshow, setAddressShow] = useState(false);
    const [checkbox, setCheckbox] = useState(true);
    const [paypalpopup, setPaypalpopup] = useState(false);
    const [addressdetails, setAddressdetails] = useState([]);
    const [add_fn, setAdd_fn] = useState('');
    const [add_ln, setAdd_ln] = useState('');
    const [add_for, setAdd_for] = useState("H");
    const [add_forO, setAdd_forO] = useState(false);
    const [add_forH, setAdd_forH] = useState(true);
    const [add_line1, setAdd_line1] = useState('');
    const [add_line2, setAdd_line2] = useState('');
    const [add_landmark, setAdd_landmark] = useState('');
    const [add_city, setAdd_city] = useState('');
    const [add_state, setAdd_state] = useState('');
    const [add_Zc, setAdd_Zc] = useState('');
    const [add_shipping, setAdd_shipping] = useState(false);
    const [add_billing, setAdd_billing] = useState(false);
    const [add_country, setAdd_country] = useState('United States');
    const [add_contact, setAdd_contact] = useState(null);
    const [curr_icon, setCurr_icon] = React.useState('');
    const [productsdata, setProductsdata] = React.useState([]);
    const [mycart, setMycart] = React.useState(localStorage.getItem("cartlength"));
    const [total_cart_price, setTotal_cart_price] = React.useState(null);
    const [discount, setDiscount] = React.useState(0);
    const [saleTax, setsaleTax] = React.useState(0);
    const [amount, setAmount] = React.useState(null);
    const [email_id, setEmail_id] = React.useState('');
    const [coupon, setCoupon] = React.useState('');
    const [checkoutData, setCheckoutData] = React.useState([]);
    const [overnightshipping, setOvernightshipping] = useState(false);
    const [add_email, setAdd_email] = useState('');
    const [add_confirmEmail, setAdd_confirmEmail] = useState('');
    const [add_fn2, setAdd_fn2] = useState('');
    const [add_ln2, setAdd_ln2] = useState('');
    const [add_for2, setAdd_for2] = useState("H");
    const [add_line12, setAdd_line12] = useState('');
    const [add_line22, setAdd_line22] = useState('');
    const [add_landmark2, setAdd_landmark2] = useState('');
    const [add_city2, setAdd_city2] = useState('');
    const [add_state2, setAdd_state2] = useState('');
    const [add_Zc2, setAdd_Zc2] = useState('');
    const [add_country2, setAdd_country2] = useState('United States');
    const [add_contact2, setAdd_contact2] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stateAbbr, setstateAbbr] = useState("");
    const [stateAbbrForShip, setstateAbbrForShip] = useState("");
    const [ifsaleTax, setifsaleTax] = useState(false);
    const [modalShow, setmodalShow] = useState(false);
    const [logInEmail, setlogInEmail] = useState("");

    const handleClose = () => setmodalShow(false);
    const handleChange3 = (event) => {
        setAdd_shipping(event.target.checked);
    };
    const handleChange4 = (event) => {
        setAdd_billing(event.target.checked);
    };
    const handleChange5 = (event) => {
        if (event.target.name == "home") {
            setAdd_for("H")
            setAdd_forH(true);
            setAdd_forO(false);

        } else if (event.target.name == "office") {
            setAdd_for("O")
            setAdd_forO(true);
            setAdd_forH(false);
        }
    };

    const selectRef = useRef(null);



    const resetadd = () => {
        setAdd_fn("");
        setAdd_ln("");
        setAdd_for("H");
        setAdd_forH(true);
        setAdd_forO(false);
        setAdd_line1("");
        setAdd_line2("");
        setAdd_landmark("");
        setAdd_city("");
        setAdd_Zc("");
        setAdd_contact(null);
        setAdd_country("");
        setAdd_state("");
        setAdd_shipping(false);
        setAdd_billing(false);
    }
    const addAddress = () => {
        const data = {
            user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
            first_name: add_fn,
            last_name: add_ln,
            address_for: add_for,
            address_line_1: add_line1,
            address_line_2: add_line2,
            landmark: add_landmark,
            city: add_city,
            state: stateAbbr,
            contact_no: add_contact,
            // country: add_country,
            country: 'US',
            zip_code: add_Zc,
            shipping: add_shipping ? 1 : 0,
            billing: add_billing ? 1 : 0
        }
        postAuthData(`/user/userAddress`, data)
            .then(res => {
                if (res.status == 1) {
                    getcartdata();
                    resetadd();
                    setAddressShow(false);
                    setAddressdetails(res.data[0]);
                    getuserAddress();
                    
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    const salePriceGuest = (fullStateName, stateAbbrivation) => {

        setAdd_state(fullStateName)
        setstateAbbr(stateAbbrivation)
        sessionStorage.setItem("stateAbbrivation", stateAbbrivation)
        saleTaxFunc(stateAbbrivation)
        // if (!isLogin) {
        //     // const dataGuest = {
        //     //     session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
        //     //     cart_price: amount,
        //     //     sale_tax_state: state,
        //     //     shipping_price: productsdata?.overnight_shipping_flag == 1 ? 15 : 0
        //     // }

        // }

    }

    const saleTaxFunc = (stateAbr) => {
        const dataGuest = {
            session_id: localStorage.getItem('session_id') ? localStorage.getItem('session_id') : 0,
            cart_price: amount,
            sale_tax_state: sessionStorage.getItem("stateAbbrivation"),
            sale_tax_country: 'US',
            sale_tax_city: add_city,
            sale_tax_zip: add_Zc,
            shipping: localStorage.getItem("productType")==0 ? 0 : JSON.parse(sessionStorage.getItem("shippingPrice"))
        }
        postData(`/order/guestsaletax`, dataGuest)
            .then((response) => {
                if (response.status == 1) {
                    setsaleTax(response?.data?.sale_tax_price);
                    setTotal_cart_price(response?.data?.total_cart_price);
                    // getcartdata()
                    setifsaleTax(true)
                } else {
                    toast.error(response.message, { autoClose: 3000 });
                    console.log(response)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const callcheckout = () => {
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        var data = {
            email_id,
            product_type: localStorage.getItem("productType1")==0 ? 0 : 1
        }
        const language = localStorage.getItem('language') ? localStorage.getItem('language') : "EN";
        const currency = localStorage.getItem('currency_type') ? localStorage.getItem('currency_type') : "USD";
        postData(`/order/createOrder?${JSON.parse(localStorage.getItem("user"))?.user_id ? "user_id=" + JSON.parse(localStorage.getItem("user"))?.user_id : ""}&coupon_code=${coupon ? coupon : ""}${billingaddress ? '&billing_address_id=' + billingaddress : ""}${shippingaddress ? '&shipping_address_id=' + shippingaddress : ""}&session_id=${session_id}&payment_mode=${payment_mode == 1 ? "Paypal" : "Stripe"}&language=${language}&currency=${currency}`, data)
            .then(res => {
                if (res.status == 1) {
                    setCheckoutData(res.data)
                    setPaypalpopup(true)
                    if (payment_mode == "2") {
                        setmodalShow(true)
                    }
                    purchase_event(res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const purchase_event = (product) => {
        let newArray=[];
        const res= product?.order_detail?.map(product => ({
          item_id: product.order_pro_id,
          item_name: product.name,
          price: product.price
        }));
        newArray.push(res)
        if (window.gtag) {
          window.dataLayer = window.dataLayer || [];
          function gtag() { window.dataLayer.push(arguments); }
          gtag('event', 'purchase', {
            event: 'purchase',
            currency: product?.currency_code,
            value: product?.final_amount,
            ecommerce: {
              currency: product?.currency_code,
              value: product?.final_amount,
              tax: product?.tax_amount,              // tax
              shipping: product?.shipping_amount,          // shipping costs
              affiliation: "gemstone king",   // affiliation (e.g. affiliate id, name of the store, etc.)
              transaction_id: product?.order_id,   
              items: newArray
            }
          });
        }
      }

    const getuserAddress = () => {
        let id = JSON.parse(localStorage.getItem("user"))?.user_id
        getData(`/user/userAddress?user_id=${id}`)
            .then(res => {
                if (res.status == 1) {
                    
                    setAddressdetails(res.data);
                    res.data.map(val => {
                        if (val.shipping == 1) {
                            setShippingaddress(val.id)
                        }
                        if (val.billing == 1) {
                            setBillingaddress(val.id)
                        }
                        if(val.email!==""){
                            setlogInEmail(val.email)

                            
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        getcartdata();
        if (isLogin) {
            getuserAddress()
        }
    }, [])

    const productType = useSelector((state) => {
        return state.persistedReducer.user.cartItems
    })

    const guestcheckout = () => {
        if (add_email != "" && add_confirmEmail != "" && add_fn != "" && add_ln != "" && add_line1 != "" && add_city != "" && add_state[0] != "" && add_contact != "" && add_country != "" && add_Zc != "") {
            var statuscheck = 1
            if (!checkbox) {
                if (add_fn2 != "" && add_ln2 != "" && add_line12 != "" && add_city2 != "" && add_state2 != "" && add_contact2 != "" && add_country2 != "" && add_Zc2 != "") {
                    statuscheck = 1
                }
                else {
                    statuscheck = 0
                    toast.error('Email & confirm email do not match!', { autoClose: 3000 });
                }
            }
            if (statuscheck == 1) {
                if (add_email == add_confirmEmail) {
                    const billing = {
                        first_name: add_fn,
                        last_name: add_ln,
                        address_for: add_for,
                        address_line_1: add_line1,
                        address_line_2: add_line2,
                        landmark: add_landmark,
                        city: add_city,
                        state: stateAbbr,
                        contact_no: add_contact,
                        // country: add_country,
                        country: 'US',
                        zip_code: add_Zc
                    }
                    const shipping = {
                        first_name: add_fn2,
                        last_name: add_ln2,
                        address_for: add_for2,
                        address_line_1: add_line12,
                        address_line_2: add_line22,
                        landmark: add_landmark2,
                        city: add_city2,
                        // state: add_state2,
                        state: stateAbbrForShip,
                        contact_no: add_contact2,
                        // country: add_country2,
                        country: 'US',
                        zip_code: add_Zc2
                    }
                    const data = {
                        email_id: add_email,
                        billing: billing,
                        shipping: checkbox ? billing : shipping,
                        product_type: localStorage.getItem("productType1")==0 ? 0 : 1
                    }
                    var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
                    postData(`/order/createOrder?${JSON.parse(localStorage.getItem("user"))?.user_id ? "user_id=" + JSON.parse(localStorage.getItem("user"))?.user_id : ""}&coupon_code=${coupon ? coupon : ""}${billingaddress ? '&billing_address_id=' + billingaddress : ""}${shippingaddress ? '&shipping_address_id=' + shippingaddress : ""}&session_id=${session_id}&payment_mode=${payment_mode == 1 ? "Paypal" : "Stripe"}`, data)
                        .then(res => {
                            if (res.status == 1) {
                                setCheckoutData(res.data)
                                setPaypalpopup(true)
                                if (payment_mode == "2") {
                                    setmodalShow(true)
                                }
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                } else {
                    toast.error('Email & confirm email do not match!', { autoClose: 3000 });
                }
            }

        }
    }

    const getcartdata = () => {
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/order/cartItems?${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}`)
            .then(res => {
                if (res.status == 1) {
                    if (res.data.cartlist?.length == 0) {
                        history.push('/')
                    }
                    setProductsdata(res.data)
                    sessionStorage.setItem("shippingPrice",res?.data?.shipping_price)
                    // productsdata?.shipping_price===""

                    setTotal_cart_price(res.data.total_cart_price)
                    setsaleTax(res.data.sale_tax_price)
                    setAmount(res.data.cart_price)
                    setOvernightshipping(res.data.overnight_shipping_flag)
                    localStorage.setItem("cartlength", res.data.cart_item_count)
                    setMycart(res.data.cart_item_count);
                    setCurr_icon(res.data.cartlist[0]?.product[0]?.currency_icon)
                  
                    res.data.cartlist?.map((val, index) => {
                        if (val.product[0].ring_size?.filter(val => val.value?.length > 0).length) {
                            if (val.ring_size == 0) {
                                history.push("/cart")
                            }
                        }
                    })
                    if (sessionStorage.getItem("coupon")?.length) {
                        getcoupon();
                    }
                } else {

                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const getcoupon = () => {
        var user_id = JSON.parse(localStorage.getItem("user"))?.user_id ? JSON.parse(localStorage.getItem("user"))?.user_id : '';
        var session_id = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : "";
        getData(`/order/coupon?${user_id ? "user_id=" + user_id : session_id ? "session_id=" + session_id : ''}&coupon_code=${sessionStorage.getItem("coupon")}`)
            .then((response) => {
                if (response.status == 1) {
                    setTotal_cart_price(response.data.final_amount)
                    setDiscount(response.data.discount_amount)
                    setAmount(response.data.total_amount)
                    setCoupon(sessionStorage.getItem("coupon"))
                    // toast.success(response.message, { autoClose: 3000 });
                    sessionStorage.removeItem("coupon")
                } else {
                    // toast.error(response.message, { autoClose: 3000 });
                    sessionStorage.removeItem("coupon")
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const redirect = (slug, sku, stone_data) => {
        history.push('/productdetail/' + slug + "/" + sku + (stone_data.length ? "/" + Object.values(stone_data)?.toString()?.replaceAll(",", "_") : ""));
    }
    const checkoutbutton = () => {
        if (!isLogin) {
            document.getElementById("formbutton").click();
            guestcheckout();
        } else {
            if (billingaddress && shippingaddress) {
                callcheckout()
            } else {
                if (!billingaddress && !shippingaddress) {
                    toast.error("Please select billing address & shipping address", { autoClose: 3000 });
                } else if (!billingaddress) {
                    toast.error("Please select billing address", { autoClose: 3000 });
                } else if (!shippingaddress) {
                    toast.error("Please select shipping address", { autoClose: 3000 });
                }
            }
        }
        localStorage.setItem("cartlength", 0)
    }
    const stateHandle = (state, stateAbbr) => {
        setAdd_state2(state)
        setstateAbbrForShip(stateAbbr)
    }

    const handlecheckform = (e) => {
        e.preventDefault();
    }
    const imageLoaded = () => {
        setLoading(true);
    }
    return (
        <>
            <Helmet>
                <title>CheckOut | Gem Stone King ™</title>
                <meta name="description" content="CheckOut" />
                <meta property="og:site_name" content="Gem Stone King ™" />
                <meta property="og:url" content="http://gsk.tasksource.in:8080/checkout" />
                <meta property="og:title" content="CheckOut" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="CheckOut" />
            </Helmet>
            <div className="gs_wishlist_content gs_myaccount">
                <Container className="gs_customer_container">
                    <Row className="m-0 w-100">
                        <div className="gs_wish_heading">
                            <h1>Checkout</h1>
                        </div>
                    </Row>
                    <Row className="pb-3">
                        <Col sm={12} md={12} lg={9} xl={9} className="">
                            <div className="gs_checkout_sec">
                                {!isLogin ? <div>
                                    <Form onSubmit={handlecheckform}>
                                        <div className="gs_myaccount_input">
                                            <Row className="w-100">
                                                <Col xs={12} sm={6} >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Email Address"
                                                        type="email"
                                                        variant="outlined"
                                                        value={add_email}
                                                        onChange={(e) => setAdd_email(e.target.value)}
                                                        required
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Confirm Email Address"
                                                        type="email"
                                                        variant="outlined"
                                                        value={add_confirmEmail}
                                                        onChange={(e) => setAdd_confirmEmail(e.target.value)}
                                                        required
                                                    />
                                                    {/* <input
                                                        id="outlined-basic"
                                                        label="Confirm Email Address"
                                                        type="email"
                                                        variant="outlined"
                                                        value={add_confirmEmail}
                                                        onChange={(e) => setAdd_confirmEmail(e.target.value)}
                                                        required
                                                    /> */}
                                                </Col>
                                              
                                            </Row>
                                        </div>
                                        <div className="gs_bill_wrapper">
                                            <h5>Billing Address</h5>
                                        </div>
                                        <div className="gs_myaccount_input">
                                            <div className="gs_checkout pr-sm-5">
                                                <Row>
                                                    <Col xs={12} sm={6} >
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="First Name"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_fn}
                                                            onChange={(e) => setAdd_fn(e.target.value)}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Last Name"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_ln}
                                                            onChange={(e) => setAdd_ln(e.target.value)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Phone Number"
                                                            variant="outlined"
                                                            type="number"
                                                            value={add_contact}
                                                            onChange={(e) => setAdd_contact(e.target.value)}
                                                            required
                                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Landmark"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_landmark}
                                                            onChange={(e) => setAdd_landmark(e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Address Line 1"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_line1}
                                                            onChange={(e) => setAdd_line1(e.target.value)}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Address Line 2"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_line2}
                                                            onChange={(e) => setAdd_line2(e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='mb-3 mb-md-0'>
                                                    <Col xs={12} sm={6}>
                                                        {/* <TextField
                                                            id="outlined-basic"
                                                            label="Country"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_country}
                                                            onChange={(e) => setAdd_country(e.target.value)}
                                                            required
                                                            readonly
                                                        /> */}

                                                        <FormControl variant="outlined" >
                                                            <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                                            <Select
                                                                labelId="country-select-outlined-label"
                                                                id="country-select-outlined"
                                                                label="Country"
                                                                value={add_country}
                                                                onChange={(e) => setAdd_country(e.target.value)}
                                                                size="small"
                                                            >
                                                                <MenuItem value="United States" >United States</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} sm={6}>

                                                        <select style={{ height: "54px" }} className='form-control' id="" value={add_state} onChange={(e) => salePriceGuest(e.target.value, e.target[e.target.selectedIndex].title)} required>
                                                            <option value="">Choose State</option>
                                                            {
                                                                usStates.length > 0 && usStates?.map((e, i) => (
                                                                    <option title={e?.abbreviation} value={e?.name} key={i}>{e?.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {/* <FormControl variant="outlined" >

                                                            <InputLabel id="state-select-outlined-label">State  14</InputLabel>
                                                            <Select
                                                                labelId="state-select-outlined-label"
                                                                id="state-select-outlined"
                                                                label="State"
                                                                value={add_state}
                                                                onChange={(e)=>salePriceGuest(e.target.value)}
                                                                size="small"
                                                                required
                                                            >
                                                                {
                                                                    usStates.length > 0 && usStates?.map((e, i) => (
                                                                        <MenuItem value={e?.name} key={i}>{e?.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl> */}
                                                        {/*
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="State"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_state}
                                                            onChange={(e) => setAdd_state(e.target.value)}
                                                            required
                                                        /> */}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="City"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_city}
                                                            onChange={(e) => setAdd_city(e.target.value)}
                                                            required
                                                            onBlur={() => saleTaxFunc()}
                                                        />

                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Zip"
                                                            type="tel"
                                                            variant="outlined"
                                                            value={add_Zc}
                                                            onBlur={() => saleTaxFunc()}
                                                            onChange={(e) => setAdd_Zc(e.target.value)}
                                                            required
                                                        //  inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={checkbox}
                                                                    onChange={(e) => setCheckbox(e.target.checked)}
                                                                    name="checkedB"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Billing address is same as shipping address"
                                                        />
                                                    </Col>
                                                </Row>
                                                {!checkbox ? <>

                                                    <div className="gs_bill_wrapper mb-5"><h5>Shipping Address</h5> </div>


                                                    <Row className="mt-3">
                                                        <Col xs={12} sm={6} >
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="First Name"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_fn2}
                                                                onChange={(e) => setAdd_fn2(e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Last Name"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_ln2}
                                                                onChange={(e) => setAdd_ln2(e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Phone Number"
                                                                type="number"
                                                                variant="outlined"
                                                                value={add_contact2}
                                                                onChange={(e) => setAdd_contact2(e.target.value)}
                                                                required
                                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Landmark"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_landmark2}
                                                                onChange={(e) => setAdd_landmark2(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Address Line 1"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_line12}
                                                                onChange={(e) => setAdd_line12(e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Address Line 2"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_line22}
                                                                onChange={(e) => setAdd_line22(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            {/* <TextField
                                                                id="outlined-basic"
                                                                label="Country"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_country2}
                                                                onChange={(e) => setAdd_country2(e.target.value)}
                                                                required
                                                            /> */}

                                                            <FormControl variant="outlined" >
                                                                <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                                                <Select
                                                                    labelId="country-select-outlined-label"
                                                                    id="country-select-outlined"
                                                                    label="Country"
                                                                    value={add_country}
                                                                    onChange={(e) => setAdd_country2(e.target.value)}
                                                                    size="small"
                                                                >
                                                                    <MenuItem value="United States" >United States</MenuItem>
                                                                </Select>
                                                            </FormControl>



                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            {/* <TextField
                                                                id="outlined-basic"
                                                                label="State"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_state2}
                                                                onChange={(e) => setAdd_state2(e.target.value)}
                                                                required
                                                            /> */}
                                                            <FormControl variant="outlined">
                                                                <select style={{ height: "54px" }} className='form-control' id="" value={add_state2} onChange={(e) => stateHandle(e.target.value, e.target[e.target.selectedIndex].title)} required>
                                                                    <option value="">Choose State</option>
                                                                    {
                                                                        usStates.length > 0 && usStates?.map((e, i) => (
                                                                            <option title={e?.abbreviation} value={e?.name} key={i}>{e?.name}</option>
                                                                        ))
                                                                    }
                                                                </select>

                                                            </FormControl>


                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="City"
                                                                type="text"
                                                                variant="outlined"
                                                                value={add_city2}
                                                                onChange={(e) => setAdd_city2(e.target.value)}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Zip"
                                                                type="tel"
                                                                variant="outlined"
                                                                value={add_Zc2}
                                                                onChange={(e) => setAdd_Zc2(e.target.value)}
                                                                required
                                                            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                            />
                                                        </Col>
                                                    </Row></> : ""}
                                                <Button style={{ visibility: "hidden" }} type="submit" id="formbutton"></Button>
                                            </div>
                                        </div></Form> </div> :
                                    <div>
                                        <div className="gs_myaccount-content">
                                            <h5>Billing Address</h5>
                                            <Row>
                                                {addressdetails?.map(val =>
                                                val?.id &&
                                                    <Col sm={6}>
                                                        <div className="gs_add_box 333">
                                                            <address>
                                                                <p><strong>Name: </strong> </p>
                                                                <p><span>{val.first_name} {val.last_name}</span></p>
                                                                <p><strong>Address: </strong> </p>
                                                                <p><span>{val.address_line_1} {val.city}, {val.state}, {val.zip_code}</span></p>
                                                                <p><strong>Mobile 3: </strong></p>
                                                                <p><span>{val.contact_no}</span></p>
                                                                <Divider />
                                                                <Button className="gs_address_btn">{val.address_for == "O" ? "Office" : "Home"}</Button>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={shippingaddress == val.id ? true : false}
                                                                            onChange={() => setShippingaddress(val.id)}
                                                                            name="checkedB"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Shipping Address"
                                                                    className="gs_top_select"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={billingaddress == val.id ? true : false}
                                                                            onChange={() => setBillingaddress(val.id)}
                                                                            name="checkedB"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Billing Address"
                                                                    className="gs_top_select"
                                                                />
                                                            </address>
                                                        </div>
                                                    </Col>)}
                                            </Row>
                                            <Button className="gs_fill_button gs_add_new" onClick={() => setAddressShow(!addressshow)}>
                                                {addressshow ? <> Add New Address <RemoveCircleIcon /> </> : <> Add New Address <AddCircleIcon /></>}
                                            </Button>
                                        </div>
                                        {addressshow ? <div className="gs_myaccount_input">
                                            <Form className="gs_checkout pr-sm-5">
                                                <Row>
                                                    <Col xs={12} sm={6} >
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="First Name"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_fn}
                                                            onChange={(e) => setAdd_fn(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Last Name"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_ln}
                                                            onChange={(e) => setAdd_ln(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Phone Number"
                                                            type="number"
                                                            variant="outlined"
                                                            value={add_contact}
                                                            onChange={(e) => setAdd_contact(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Landmark"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_landmark}
                                                            onChange={(e) => setAdd_landmark(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Address Line 1"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_line1}
                                                            onChange={(e) => setAdd_line1(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Address Line 2"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_line2}
                                                            onChange={(e) => setAdd_line2(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='mb-2 mb-md-0'>
                                                    <Col xs={12} sm={6}>
                                                        {/* <TextField
                                                            id="outlined-basic"
                                                            label="Country"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_country}
                                                            onChange={(e) => setAdd_country(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            readonly
                                                        /> */}

                                                        <FormControl variant="outlined" >
                                                            <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                                            <Select
                                                                labelId="country-select-outlined-label"
                                                                id="country-select-outlined"
                                                                label="Country"
                                                                value={add_country}
                                                                onChange={(e) => setAdd_country(e.target.value)}
                                                                size="small"
                                                            >
                                                                <MenuItem value="United States" >United States</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Col>
                                                    <Col xs={12} sm={6}>



                                                        <select style={{ height: "54px" }} className='form-control' id="" value={add_state} onChange={(e) => salePriceGuest(e.target.value, e.target[e.target.selectedIndex].title)} required>
                                                            <option value="">Choose State</option>
                                                            {
                                                                usStates.length > 0 && usStates?.map((e, i) => (
                                                                    <option title={e?.abbreviation} value={e?.name} key={i}>{e?.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {/* <FormControl variant="outlined" >
                                                            <InputLabel id="state-select-outlined-label">State 13</InputLabel>
                                                            <Select
                                                                labelId="state-select-outlined-label"
                                                                id="state-select-outlined"
                                                                label="State"
                                                                value={add_state}
                                                                onChange={(e)=>salePriceGuest(e.target.value)}
                                                                //onChange={(e) => setAdd_state(e.target.value)}
                                                                size="small"
                                                                required
                                                                onKeyDown={(e)=>hitTab(e)}
                                                            >
                                                                {
                                                                    usStates.length > 0 && usStates?.map((e, i) => (
                                                                        <MenuItem value={e?.name} key={i}>{e?.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl> */}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="City"
                                                            type="text"
                                                            variant="outlined"
                                                            value={add_city}
                                                            onBlur={() => saleTaxFunc()}
                                                            onChange={(e) => setAdd_city(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Zip"
                                                            type="number"
                                                            variant="outlined"
                                                            value={add_Zc}
                                                            onBlur={() => saleTaxFunc()}
                                                            onChange={(e) => setAdd_Zc(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={add_shipping}
                                                                    onChange={handleChange3}
                                                                    name="checkedB"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Default Shipping Address"
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={add_forH}
                                                                    onChange={handleChange5}
                                                                    name="home"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Home"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={add_billing}
                                                                    onChange={handleChange4}
                                                                    name="checkedB"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Default Billing Address"
                                                        />
                                                    </Col>

                                                    <Col xs={12} sm={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={add_forO}
                                                                    onChange={handleChange5}
                                                                    name="office"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Office"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button onClick={addAddress} className="gs_fill_button m-auto d-block">
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div> : ""} </div>}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={3} xl={3} className="">

                            <div className="gs_cart_summary1">
                                <h4>ORDER SUMMARY </h4>
                                <div className="gs_cart_summaryitems">
                                    {productsdata?.cartlist?.map((val, index) =>
                                        <div className="gd_order_details">
                                            <Row onClick={() => { window.scrollTo(0, 0); redirect(val.product[0].product_slug, val.product[0].Product_sku, val.product[0].stone_data) }}>
                                                <Col xs={12} sm={4}>
                                                    {isMobileOnly ?
                                                        <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={70} height={70} /> : isTablet ? <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={70} height={70} /> : <Skeleton style={{ display: loading ? "none" : "block" }} variant="rect" animation="wave" width={70} height={70} />
                                                    }
                                                    <Image style={{ display: loading ? "block" : "none" }} onLoad={() => imageLoaded(val.product[0].img_url)} src={val.product[0].img_url}></Image>
                                                </Col>
                                                <Col xs={12} sm={8}>
                                                    <h2 >{val.product[0].product_name} </h2>
                                                    {val.product[0].ring_size?.filter(val => val.value?.length > 0).length ?
                                                        <span>Ring Size: {val.ring_size}</span> : ""}
                                                    <span>{curr_icon}{val.product[0]?.sale_price}</span>
                                                </Col>
                                            </Row>
                                        </div>)}
                                </div>
                                <div className="gs_cart_box">
                                    <div className="gs_cart_row">
                                        <p className="gs_summary_label"> Total</p>
                                        <p>{curr_icon}{amount}</p>
                                    </div>
                                    <div className="gs_cart_row">
                                        <p className="gs_summary_label"> Discount</p>
                                        <p>{curr_icon}{discount}</p>
                                    </div>
                                    <div className="gs_cart_row">
                                        <p className="gs_summary_label"> Sale Tax</p>
                                        <p>{curr_icon + saleTax}</p>
                                    </div>

                                    {overnightshipping > 0 ?
                                        <div className="gs_cart_row">
                                            <p className="gs_summary_label">Shipping Charges</p>
                                            <p>{curr_icon}{productsdata?.shipping_price}</p>

                                        </div> : ""}
                                    <div className="gs_cart_row">
                                        <p className="gs_summary_label"> Total Payable</p>
                                        <p>{curr_icon + total_cart_price}</p>
                                    </div>
                                    <div className="gs_ship_row">
                                        <p className="gs_summary_label"> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>  Delivery by:   <span><strong> {productsdata.estimate_delivery} </strong></span></p>
                                    </div>
                                    <div className='paymentType mb-4 d-flex flex-wrap'>
                                         <div className='mr-3'>

                                            <label className="form-check-label pointer mb-2" for="stripeModeGateway" onClick={() => setPayment_mode(2)}>
                                                <input className="" type="radio" name="gateway" id="stripeModeGateway" checked={payment_mode == 2 ? true : false} />
                                                <span className='creditCard ml-2'>Credit/Debit Card</span>
                                                {/* <img className='ml-2' src={require("../../assets/images/stripe_icon.jpg").default} alt="" /> */}
                                            </label>
                                        </div>
                                        <div>

                                            <label className="form-check-label pointer" for="paypalModeGateway" onClick={() => setPayment_mode(1)}>
                                                <input className="" type="radio" name="gateway" id="paypalModeGateway" checked={payment_mode == 1 ? true : false} />
                                                <img className='ml-2' src={require("../../assets/images/paypal_icon.jpg").default} alt="" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="text-center">


                                        {paypalpopup ? (
                                            <>
                                                {
                                                    payment_mode == "1" && <Paypal id="paypalbutton" data={checkoutData} />

                                                }

                                            </>

                                        ) : <Button onClick={() => checkoutbutton()} className="gs_check_btn mt-2">
                                            proceed to payment
                                        </Button>}
                                    </div>
                                    <div className="gs_accept_card">
                                        <p>We Accept</p>
                                        <img src={payment_icon} alt="Payment"></img>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                (payment_mode == "2" && paypalpopup) &&
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                    onHide={() => handleClose()}
                    className='stripeModal'
                    show={modalShow}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Elements stripe={stripePromise}>
                            <Stripe data={checkoutData} email={logInEmail !=="" ? logInEmail : add_confirmEmail} />
                        </Elements>
                    </Modal.Body>
                </Modal>
            }

        </>
    )
}

export default Checkout
